<template>
  <div v-if="isshowgrid">
    <va-card title="Transaction List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" v-if="((getRole() == 'OPERATOR') || (getRole() == 'RESELLER') || (getRole() == 'HEPI_OPERATOR') || (getRole() == 'HEPI_RESELLER'))"  @click="add()">Add Credit</va-button>
          <va-button color="success" icon="entypo entypo-export"  @click="exportTransactionDetails()">Export</va-button>
        </div>
      </div>
      <div class="row-md-12 col-md-12">

        <div class="container">
          <div class="row flex md12">
            <label class="date_picker_label flex md2 va-form-label va-form-required-label"><b>Start Date</b></label>
            <label class="date_picker_label flex md2 ml-4 va-form-label va-form-required-label"><b>End Date</b></label>
          </div>
          <div class="row">
            <div class="row align--center">
              <datepicker
                v-model="cal_start_date"
                format="dd-MM-yyyy"
                :disabledDates="toDisabledDates"
                placeholder="DD-MM-YYYY"
                style="margin-left: 22px;"
              />
              <datepicker
                v-model="cal_end_date"
                format="dd-MM-yyyy"
                :disabledDates="toDisabledDates"
                placeholder="DD-MM-YYYY"
                style="margin-left: 10px;"
              />
              <va-button icon="fa fa-filter" @click.prevent="getAllTransaction()"></va-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex xs12 md12">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs grow v-model="tabValue" style="width: 400px;">
                <va-tab v-for="title in tabTitles" :key="title">
                  {{title}}
                </va-tab>
              </va-tabs>
            </div>
          </div>
          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            :totalPages="totalPages"
            @page-selected="getAllTransaction"
            api-mode
            style="min-height: 60vh;"
          >
            <template slot="status" slot-scope="props">
              <span v-bind:class="{ 'va-success-color' : (props.rowData.status === 'Approved'),
                                    'va-pending-color' : (props.rowData.status === 'Pending'),
                                    'va-error-color' : (props.rowData.status === 'Declined')}">
                {{props.rowData.status}}
              </span>
            </template>
            <template slot="bundle" slot-scope="props">
              <img src="@/assets/GridBundle.png" @click="bundlepopup(props.rowData.bundle)" style="width: 20px; height: 20px; margin-top: 2px; cursor: pointer;" />
            </template>
            <template slot="is_moq" slot-scope="props">
              <va-button title="Not an MOQ cost!" v-if="props.rowData.is_moq === false" flat medium color="red" icon="fa fa-times" class="ma-0 mb-1"></va-button>
              <va-button title="MOQ cost!" v-if="props.rowData.is_moq === true" flat medium color="green" icon="fa fa-check" class="ma-0"></va-button>
            </template>

            <template slot="approve" slot-scope="props">
              <va-button small
                v-if="creditType !=  'Credit' && props.rowData.status == 'Pending' && props.rowData.enable_reseller_bundle_creation && ((getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER')) && resellerTransFlag"
                icon="entypo entypo-thumbs-up"
                @click="statusUpdate('Approved',props.rowData)"
                class="ma-0 accept-btn"> Approve
              </va-button>
              <va-button small
                v-if="props.rowData.status == 'Pending' && (getRole() == 'FINANCE')"
                icon="entypo entypo-thumbs-up"
                @click="statusUpdate('Approved',props.rowData)"
                class="ma-0 accept-btn"> Approve
              </va-button>
            </template>
            <template slot="decline" slot-scope="props">
              <va-button
                small
                v-if="creditType !=  'Credit' && props.rowData.status == 'Pending' && props.rowData.enable_reseller_bundle_creation && ((getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER')) && resellerTransFlag"
                icon="entypo entypo-thumbs-down"
                @click="statusUpdate('Declined',props.rowData)"
                class="ma-0 decline-btn"> Decline
              </va-button>
              <va-button
                small
                v-if="props.rowData.status == 'Pending' && (getRole() == 'FINANCE')"
                icon="entypo entypo-thumbs-down"
                @click="statusUpdate('Declined',props.rowData)"
                class="ma-0 decline-btn"> Decline
              </va-button>
            </template>
            <template slot="users" slot-scope="props">
              <va-button
                flat
                small
                v-if="props.rowData.mobile"
                icon="entypo entypo-users"
                @click="useraddress(props.rowData)"
                class="ma-0">
              </va-button>
            </template>
          </va-data-table>
        </div>
      </div>
      <va-modal
        v-model="isUserList"
        size="small"
        noEscDismiss
        noOutsideDismiss
        @ok="list()"
        cancelText=""
        class="flex md12"
      >
        <template #header><h2>User Details</h2></template><br>
        <div class="ml-3 mr-3">
          <div class="mt-2 row">
            <span class="flex xs5"><b>Name</b></span>
            <span align=right>: {{selectedRow.name}}</span>
          </div>
          <div class="mt-2 row">
            <span class="flex xs5"><b>Mobile</b></span>
            <span align=right>: {{selectedRow.mobile}}</span>
          </div>
          <div class="mt-2 row" v-if="selectedRow.serial_no">
            <span class="flex xs5"><b>Serial Number</b></span>
            <span align=right>: {{selectedRow.serial_no}}</span>
          </div>
          <div class="mt-2 row" v-if="selectedRow.mac_address">
            <span class="flex xs5"><b>Mac Address</b></span>
            <span align=right>: {{selectedRow.mac_address}}</span>
          </div>
          <div class="mt-2 row" v-if="selectedRow.mode">
            <span class="flex xs5"><b>Mode</b></span>
            <span align=right>: {{selectedRow.mode}}</span>
          </div>
        </div>
      </va-modal>
      <va-modal
        v-model="isbundleshow"
        size="small"
        noEscDismiss
        noOutsideDismiss
        @ok="list()"
        cancelText=""
        class="flex md12"
      >
        <template #header><h2>Bundle</h2></template>
        <va-button flat small color="danger" icon="entypo entypo-cancel" class="model_cancel" @click="list()"></va-button><br>
        <div class="ml-4">
          <span class="pre-formatted">{{bundledata}}</span>
        </div>
      </va-modal>

    </va-card>
  </div>

  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form class="mt-3">
            <div class="ml-2">
              <fieldset class="row">
                <va-radio-button
                  v-if="priceFlag && ((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR'))"
                  option="Credit"
                  v-model="credit_type"
                  label="Credit"
                  @input="credit_method(credit_type)"
                  :disabled="true"
                />
                <va-radio-button
                  v-if="priceFlag && ((getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER'))"
                  option="Credit"
                  v-model="credit_type"
                  label="Credit"
                  @input="credit_method(credit_type)"
                />
                <va-radio-button
                  v-if="priceFlag && (getRole() == 'RESELLER' || getRole() == 'HEPI_RESELLER') && getCustomFlag() == 1"
                  option="Operator Credit"
                  v-model="credit_type"
                  label="Operator Credit"
                  @input="credit_method(credit_type)"
                />
              </fieldset>
            </div><br>
            <span class="va-form-label va-form-required-label" v-if="selectOrg">Organization Name</span>
            <va-select
              v-if="selectOrg"
              placeholder="Select Organization Name"
              v-model="org_name"
              textBy="org_name"
              :options="orgNameObj"
              @input="giveCreditToOrg(org_name)"
              :error="!!orgNameErrors.length"
              :error-messages="orgNameErrors"
              noClear
              required="true"
            />
            <div v-if="priceFlag && !manualFlag" class="ml-1">
              <label><h3>Total Amount</h3>{{total_amount}}</label>
            </div>
            <div v-if="priceFlag && manualFlag" class="mt-2 ml-1">
              <span class="va-form-label va-form-required-label">Total Amount</span>
              <va-input
                v-model="total_amount"
                min=1
                type="number"
                placeholder="Enter Total Amount"
              />
            </div>
            <span class="va-form-label va-form-required-label" v-if="priceFlag && !selectOrg">Payment Method</span>
            <div class="ml-2 mt-3">
              <fieldset class="row" v-if="priceFlag && !selectOrg">
                <va-radio-button
                  option="Online"
                  v-model="method"
                  label="Online"
                  @input="pay(method)"
                />
                <va-radio-button
                  option="Offline"
                  v-model="method"
                  label="Offline"
                  @input="pay(method)"
                />
              </fieldset>
            </div>
            <div class="mt-3">
              <span v-if="offlineMethod && !selectOrg" class="va-form-label va-form-required-label">Reference Number</span>
              <va-input
                class="mt-1"
                v-if="offlineMethod && !selectOrg"
                v-model.trim="reference_number"
                type="text"
                placeholder="Enter Reference Number"
              />
            </div>
            <va-modal
              v-model="showPayModal"
              title="Make Payment"
              size='large'
              okText="Pay"
              cancelText="Cancel"
              @ok="createTransaction()"
              @cancel="cancel()">
              <va-input
                v-model="total_amount"
                type="number"
                label="Total Amount"
                :disabled="true"
              />
              <fieldset class="row">
                <va-radio-button
                  option="Online"
                  v-model="method"
                  label="Online"
                  @input="pay(method)"
                />
                <va-radio-button
                  option="Offline"
                  v-model="method"
                  label="Offline"
                  @input="pay(method)"
                />
              </fieldset>
              <va-input
                v-if="offlineMethod"
                v-model="reference_number"
                type="number"
                placeholder="Enter Reference Number"
              />
            </va-modal>
            <va-modal
              v-model="showConfirmModal"
              title="Confirm Payment"
              message='Are you sure to pay?'
              size='large'
              okText="Pay Now"
              cancelText="Cancel"
              @ok="payNow()"
              @cancel="cancel()">
            </va-modal>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createTransaction()">Pay</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
  <div v-else-if="msgFlag">
    <va-card><center><b>{{msg}}</b></center></va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import Datepicker from 'vuejs-datepicker'
import config from '../../i18n/en.json'
import { search } from '../../i18n/search.js'
import { debounce } from 'lodash'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)
export default {
  components: { Datepicker },
  name: 'transaction',
  created (page = 0) {
    var role = Vue.$cookies.get('userRole')
    var vm = this
    if (role == 'FINANCE') {
      this.getAllTransaction()
    } else {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/bundle/operator/content').then(resp => {
        loader.hide()
        if ((role === 'OPERATOR') || (role === 'RESELLER') || (role === 'HEPI_RESELLER') || (role === 'HEPI_OPERATOR' )) {
          if((role !== 'OPERATOR') && (role !== 'HEPI_OPERATOR')){
            this.tabTitles.push('Operator Credit')
          }
          this.$http.get(config.menu.host + '/operator_setting').then(oper => {
            this.all_oper_data = oper.body
            oper.body.map(function (property, i) {
              if (property.org_id == Vue.$cookies.get('org-id')) {
                vm.moq_cost = property.moq_cost
                vm.moq_duration = property.moq_duration
                vm.moq_slab_name = property.moq_slab_name
                vm.moq_slab_list = property.moq_slab_list
                vm.operatorRole = !!property.enable_bundle_creation
              }
              if (i == (oper.body.length - 1)) {
                vm.$http.get(config.menu.host + '/transaction/credit/calculation').then(respo => {
                  vm.creditCalculated = respo.body
                  vm.$http.get(config.menu.host + '/org').then(resp => {
                    resp.body.map(function (prop, i) {
                      prop.id = i + 1
                      if((prop.org_type == 'OPERATOR' || prop.org_type == 'HEPI_OPERATOR') && (prop.reseller_org_id == Vue.$cookies.get('reseller_id'))) {
                        vm.orgNameObj.push(prop)
                      }
                    })
                    vm.getAllTransaction()
                  })
                }, function (err) {
                })
              }
            })
          })
        } else {
          this.getAllTransaction()
        }
      }, error => {
        loader.hide()
        if (error && error.body) {
	        this.msgFlag = true
          this.msg = 'Operator setting not found. Please contact your admin!'
        }
      })
    }
  },
  data () {
    return {
      totalPages: 0,
      term: null,
      perPage: '10',
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showConfirmModal: false,
      priceFlag: false,
      showPayModal: false,
      offlineMethod: false,
      isbundleshow: false,
      bundledata: '',
      bundle: '',
      reference_number: '',
      moq: '',
      method: 'Online',
      total_amount: 0,
      title: '',
      transactionData: [],
      transactionDetails: [],
      bundleArr: [],
      bundleErrors: [],
      msgFlag: false,
      msg: '',
      toDisabledDates: { from: new Date(Date.now()) },
      operatorRole: false,
      moq_cost: '',
      moq_duration: '',
      moq_slab_list: [],
      moq_slab_name: '',
      is_moq: false,
      carry_forwarded: 0,
      durationObj: {
        '30 days': 30,
        '90 days': 90,
        '180 days': 180,
        '360 days': 360,
      },
      creditCalculated: '',
      credit_type: 'Credit',
      orgNameErrors: [],
      org_name: '',
      orgNameObj: [],
      selectOrg: false,
      all_oper_data: [],
      tabTitles: ['Credit'],
      tabValue: 0,
      resellerTransFlag: false,
      cal_start_date: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      cal_end_date: new Date(),
      isUserList: false,
      selectedRow: {},
      creditType: ''
    }
  },
  watch: {
    tabValue () {
      if (this.tabValue == 0) {
        this.resellerTransFlag = ((Vue.$cookies.get('userRole') != 'RESELLER') || (Vue.$cookies.get('userRole') != 'HEPI_RESELLER')) ? true : false
        this.getAllTransaction()
      } else if (this.tabValue == 1) {
        this.resellerTransFlag = true
        this.getAllTransaction()
      }
    },
  },
  computed: {
    formReady () {
      return !this.bundleErrors.length
    },
    fields () {
      if (this.tabValue == 0 && ((Vue.$cookies.get('userRole') == 'OPERATOR') || (Vue.$cookies.get('userRole') == 'RESELLER'))) {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        }, {
          name: '__slot:marker',
          width: '30px',
          dataClass: 'text-center',
        },
        {
          name: 'trans_id',
          title: 'Transaction Id',
        },
        {
          name: 'payment_method',
          title: 'Payment Type',
        },
        {
          name: 'reference_number',
          title: 'Reference Number',
        },
        {
          name: 'time_stamp',
          title: 'created At',
        },
        {
          name: 'type',
          title: 'Type',
        },
        {
          name: 'total_amount',
          title: 'Amount',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: '__slot:bundle',
          title: 'Bundle',
        },
        {
          name: 'pre_bundle',
          title: 'Pre-activated bundle',
        },
        {
          name: '__slot:users',
          title: 'user',
        },
        {
          name: '__slot:is_moq',
          title: 'MOQ cost',
        },
        {
          name: '__slot:approve',
          width: '10%',
        },
        {
          name: '__slot:decline',
          width: '10%',
        }]
      }else if (this.tabValue == 0 && (Vue.$cookies.get('userRole') == 'FINANCE')) {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        }, {
          name: '__slot:marker',
          width: '30px',
          dataClass: 'text-center',
        },
        {
          name: 'trans_id',
          title: 'Transaction Id',
        },
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'payment_method',
          title: 'Payment Type',
        },
        {
          name: 'reference_number',
          title: 'Reference Number',
        },
        {
          name: 'time_stamp',
          title: 'created At',
        },
        {
          name: 'type',
          title: 'Type',
        },
        {
          name: 'total_amount',
          title: 'Amount',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: '__slot:bundle',
          title: 'Bundle',
        },
        {
          name: 'pre_bundle',
          title: 'Pre-activated bundle',
        },
        {
          name: '__slot:users',
          title: 'user',
        },
        {
          name: '__slot:is_moq',
          title: 'MOQ cost',
        },
        {
          name: '__slot:approve',
          width: '10%',
        },
        {
          name: '__slot:decline',
          width: '10%',
        }]
      } else {
        return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
        }, {
          name: '__slot:marker',
          width: '30px',
          dataClass: 'text-center',
        },
        {
          name: 'trans_id',
          title: 'Transaction Id',
        },
        {
          name: 'org_name',
          title: 'Organization Name',
        },
        {
          name: 'payment_method',
          title: 'Payment Type',
        },
        {
          name: 'reference_number',
          title: 'Reference Number',
        },
        {
          name: 'time_stamp',
          title: 'created At',
        },
        {
          name: 'type',
          title: 'Type',
        },
        {
          name: 'total_amount',
          title: 'Amount',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: '__slot:bundle',
          title: 'Bundle',
        },
        {
          name: 'pre_bundle',
          title: 'Pre-activated bundle',
        },
        {
          name: '__slot:users',
          title: 'user',
        },
        {
          name: '__slot:is_moq',
          title: 'MOQ cost',
        },
        {
          name: '__slot:approve',
          width: '10%',
        },
        {
          name: '__slot:decline',
          width: '10%',
        }]
      }
    },
    filteredData () {
      return search(this.term, this.transactionData)
    },
  },
  methods: {
    getRole () {
      return Vue.$cookies.get('userRole')
    },
    getCustomFlag () {
      return Vue.$cookies.get('enable_reseller_bundle_creation')
    },
    statusUpdate (status, payment) {
      var payload = {
        status: status,
        transaction_id: payment.transaction_id,
      }
      this.$http.put(config.menu.host + '/transaction/' + payment.transaction_id, payload)
        .then((res) => {
          Vue.notify({ text: res.body, type: 'success' })
          payment.status = status
          this.getAllTransaction()
        })
    },
    pay (method) {
      if (method === 'Offline') {
        this.offlineMethod = true
      } else {
        this.reference_number = ''
        this.offlineMethod = false
      }
    },
    getOwnCredit () {
      this.transactionData = this.transactionData
    },
    getOperatorCredit () {
      this.transactionData = this.transactionData
    },
    credit_method (method) {
      this.total_amount = 1
      this.org_name = ''
      if (method === 'Credit') {
        this.selectOrg = false
        this.add()
      } else {
        this.selectOrg = true
      }
    },
    useraddress (data) {
      this.isUserList = true
      this.selectedRow = data
    },
    giveCreditToOrg (org) {
      var flag = false
      var vm = this
      var slab = ''
      var operator = ''
      this.all_oper_data.map(function (data) {
        if (data.org_id == org.org_id) {
          operator = data
          var current_date = new Date()
          if (data.moq_slab_list && data.moq_slab_list.length > 0) {
            loop1:
            for (var i = 0; i < data.moq_slab_list.length; i++) {
              var oper = data.moq_slab_list[i]
              if ((current_date.getTime() >= (new Date(oper.start_date)).getTime()) && (current_date.getTime() <= (new Date(oper.end_date)).getTime())) {
                flag = true
                slab = oper
                break loop1
              }
              if (i == (data.moq_slab_list.length - 1)) {
                if (!flag) {
                  Vue.notify({ text: 'No MoQ Slab available for current date', type: 'error' })
                }
              }
            }
            const loader = Vue.$loading.show({ width: 40, height: 40 })
            vm.$http.get(config.menu.host + '/transaction/slab/data/' + slab.start_date + '/' + slab.end_date + '/' + operator.org_id).then((res) => {
              loader.hide()
              if (res.body.trans.length > 0) {
                vm.manualFlag = true
                vm.priceFlag = true
              } else {
                vm.total_amount = Number((slab.cost - res.body.carry_forwarded).toFixed(2))
                if (vm.creditCalculated.object >= vm.total_amount) {
                  vm.manualFlag = false
                  vm.priceFlag = true
                } else {
                  Vue.notify({ text: 'You have less credit in your account. So Please add that!', type: 'error' })
                }
              }
            })
          } else {
            vm.is_moq = true
            vm.total_amount = operator.moq_cost
            var days = vm.durationObj[operator.moq_duration]
            var start_date = new Date()
            var expiry = new Date()
            var end_date = new Date(expiry.setDate(expiry.getDate() + days)).setHours(23, 59, 59, 999)
            vm.moq_slab_list = [{ start_date: start_date, end_date: new Date(end_date), cost: operator.moq_cost, duration: operator.moq_duration, moq_name: operator.moq_slab_name }]
            vm.manualFlag = false
            vm.priceFlag = true
          }
        }
      })
    },
    getAllTransaction (page = 0) {
      var start_date = this.cal_start_date.getFullYear() + '-' + ('0' + (this.cal_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.cal_start_date.getDate())).slice(-2)
      var end_date = this.cal_end_date.getFullYear() + '-' + ('0' + (this.cal_end_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.cal_end_date.getDate())).slice(-2)
      if (end_date >= start_date) {
        if (this.term) {
          return this.search(this.term, page)
        }
        var vm = this
        var role = this.$cookies.get('userRole')
        var role_org_id = Vue.$cookies.get('org-id')
        this.creditType = (this.tabValue == 0) ? 'Credit' : 'Operator Credit'
        const loader = Vue.$loading.show({ width: 40, height: 40 })

        this.$http.get(config.menu.host + '/transaction/get/' + this.perPage + '/' + page + '/' + this.creditType + '/' + this.cal_start_date + '/' + this.cal_end_date).then(response => {
          loader.hide()
          let index = 0
          this.totalPages = response.body.count
          var start = (page - 1).toString()
          let num = (page == 0) ? 1 : Number(start + 1)
          response.body.rows.map(function (item) {
            item.id = index++
            item.sno = num++
            item.is_existing = true
            item.checkbox_value = false
          })
          this.transactionData = response.body.rows
          this.isshowgrid = true
          function QueryStringToJSON () {
            var pairs = location.search.slice(1).split('&')
            var result = {}
            pairs.forEach(function (pair) {
              pair = pair.split('=')
              result[pair[0]] = decodeURIComponent(pair[1] || '')
            })
            return JSON.parse(JSON.stringify(result))
          }
          var query_string = QueryStringToJSON()
          var reseller_condition = Vue.$cookies.get('enable_reseller_bundle_creation')
          var payload = {
            query: query_string,
            reseller_condition: reseller_condition,
          }
          var domain = location.host
          var url = 'https://' + domain
          if (Object.keys(query_string).length > 1) {
            this.$http.put(config.menu.host + '/transaction/update_payment_status', payload).then(response => {
              location = url + '/#/admin/transaction'
            })
          }
        })
      } else {
        return Vue.notify({ text: 'Invalid date!', type: 'error' })
      }
    },
    payNow () {
      window.open(this.credit_url, '_self')
    },
    createTransaction () {
      if ((this.method === 'Offline') && (!this.reference_number)) {
        return Vue.notify({ text: 'Please enter reference number', type: 'error' })
      }
      if (this.credit_type == 'Operator Credit') {
        this.orgNameErrors = this.org_name.org_name ? [] : ['Organization is required']
        if (this.orgNameErrors.length > 0) {
          return Vue.notify({ text: 'Please select Organization', type: 'error' })
        } else if (typeof (this.creditCalculated.object) === 'object') {
          return Vue.notify({ text: 'You have less credit in your account. So Please add that!', type: 'error' })
        }
        // else if(this.creditCalculated.object < this.total_amount){
        //   return Vue.notify({ text: 'You have less credit in your account. So Please add that!', type: 'error' })
        // }
      }
      var enable_reseller_bundle_creation = Vue.$cookies.get('enable_reseller_bundle_creation')
      if (enable_reseller_bundle_creation == 1) {
        var reseller_bundle = 1
      } else {
        var reseller_bundle = 0
      }
      var domain = location.host
      var url = 'https://' + domain
      var payload = {
        bundle: this.moq_slab_name,
        moq: this.moq,
        redirection_url: url,
        reference_number: this.reference_number,
        payment_method: (this.credit_type == 'Operator Credit') ? 'Offline' : this.method,
        total_amount: this.total_amount,
        type: 'Credit',
        status: 'Pending',
        criteria: 'Direct',
        is_moq: this.is_moq,
        carry_forwarded: this.carry_forwarded,
        moq_slab_list: this.moq_slab_list,
        credit_type: this.credit_type,
        org_details: this.org_name,
        oper_moq: !this.manualFlag,
        enable_reseller_bundle_creation: reseller_bundle,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/transaction', payload).then(resp => {
        loader.hide()
        if ((this.method === 'Online') && (this.credit_type == 'Credit')) {
          this.showConfirmModal = true
          this.credit_url = resp.body
          // this.list()
        } else if (this.credit_type == 'Operator Credit') {
          Vue.notify({ text: resp.body, type: 'success' })
          this.list()
        } else if ((this.method === 'Offline') && (this.credit_type == 'Credit')) {
          Vue.notify({ text: 'Transaction is being process', type: 'success' })
          this.list()
        }
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    download (row) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/transaction/' + row.transaction_id).then(response => {
        loader.hide()
        var link = document.createElement('a')
        var url = 'data:text/pdf;base64,' + escape(response.body.payload)
        link.href = url
        link.download = response.body.file_name
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, err => {
        loader.hide()
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = 'Add Credit'
      this.bundle = ''
      this.moq = ''
      this.total_amount = ''
      this.method = 'Online'
      // this.priceFlag = false
      this.offlineMethod = false
      this.total_amount = 0
      this.reference_number = ''
      this.selectOrg = false
      if ((this.moq_slab_list == null) || (this.moq_slab_list.length == 0)) {
        this.total_amount = this.moq_cost
        this.priceFlag = true
        this.manualFlag = false
        this.is_moq = true
        var days = this.durationObj[this.moq_duration]
        var start_date = new Date()
        var expiry = new Date()
        var end_date = new Date(expiry.setDate(expiry.getDate() + days)).setHours(23, 59, 59, 999)
        this.moq_slab_list = [{ start_date: start_date, end_date: new Date(end_date), cost: this.moq_cost, duration: this.moq_duration, moq_name: this.moq_slab_name }]
      } else {
        if (this.creditCalculated.carry_forwarded >= 0) {
          this.carry_forwarded = this.creditCalculated.carry_forwarded
          this.total_amount = Number((this.moq_cost - this.creditCalculated.carry_forwarded).toFixed(2))
      	  if (this.total_amount < 0) {
       		  this.total_amount = -(this.total_amount)
      	  }
          this.priceFlag = true
          this.manualFlag = false
          this.is_moq = true
        } else {
          this.total_amount = 1
          this.priceFlag = true
          this.manualFlag = true
          this.is_moq = false
        }
      }
      this.bundleErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    bundlepopup (bundledata) {
      function replaceCommaLine (data) {
        let dataToArray = data.split(',')
        dataToArray = dataToArray.map(function (item, i) {
          var datas = (i + 1) + '.  ' + item.trim()
          return datas
        })
        return dataToArray.join('\n')
      }
      this.isbundleshow = true
      const availableData = bundledata
      this.bundledata = replaceCommaLine(availableData)
    },
    exportTransactionDetails () {
      var rowKeys = {
        trans_id: 'Transaction ID',
        org_name: 'Organization Name',
        name: 'Customer Name',
        mobile: 'Contact Number',
        time_stamp: 'Date of Transaction',
        mac_address: 'MAC ID',
        serial_no: 'Serial Number',
        type: 'Type of Transaction',
        total_amount: 'Amount',
        status: 'Status',
        bundlevalue:'Bundles',
        mode: 'Mode'
      }
      var start_date = this.cal_start_date.getFullYear() + '-' + ('0' + (this.cal_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.cal_start_date.getDate())).slice(-2)
      var end_date = this.cal_end_date.getFullYear() + '-' + ('0' + (this.cal_end_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (this.cal_end_date.getDate())).slice(-2)
      if (end_date >= start_date) {
        if (this.term) {
          return this.search(this.term, page)
        }
        this.creditTypeData = (this.tabValue == 0) ? 'Credit' : 'Operator Credit'
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/transaction/get/' + this.creditTypeData + '/' + this.cal_start_date + '/' + this.cal_end_date).then(response => {
          loader.hide()
          response.body.map(function(data){
            if(data.bundle){
            data['bundlevalue'] = data.bundle.split(',').join(';')
            }
          })
          this.transactionDetails = response.body
          exportToFile(this.transactionDetails, rowKeys, 'Transaction', 'CSV')
        })
      } else {
        Vue.notify({ text: 'Invalid Date', type: 'error' })
      }
    },
    list () {
      this.tabValue = 0
      this.isbundleshow = false
      this.isUserList = false
      this.isshowForm = false
      this.isshowgrid = true
      this.cal_start_date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
      this.cal_end_date = new Date()
      this.getAllTransaction()
    },
    search: debounce(function (term, page = 0) {
      this.creditType = (this.tabValue == 0) ? 'Credit' : 'Operator Credit'
      this.term = term
      if (term) {
        var payload = {
          term: term,
          limit: this.perPage,
          offset: page,
          creditType:this.creditType
        }
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.post(config.menu.host + '/transaction/search', payload).then(response => {
          loader.hide()
          let index = 0
          var start = (page - 1).toString()
          let num = (page == 0) ? 1 : Number(start + 1)
          this.totalPages = response.body.count
          this.transactionData = response.body.rows.map(function (item) {
            item.id = index++
            item.sno = num++
            item.is_existing = true
            item.checkbox_value = false
            return item
          // }
          })
          this.isshowForm = false
          this.isshowgrid = true

        })
      } else {
        this.getAllTransaction()
      }
    }, 400),
  },
}
</script>

<style lang="scss">
  .accept-btn {
    color: $alpha-color;
    background: $success-color !important;
    border-radius: 10px;
  }

  .decline-btn {
    color: $alpha-color;
    background: $error-color !important;
    border-radius: 10px;
  }

  .cursor {
    cursor: revert;
  }

  .top {
    margin-top: 27px;
  }

  .date_picker_label {
    font-size: 13px;
    color: #3385ff;
  }

  .width {
    width: 150px;
  }

  .left {
    margin-left: 6px;
    color: black;
  }

</style>
